console.log('twideo v0.0.9')

const $getVideoButton = document.querySelector('.get-video-button')
const $urlInput = document.querySelector('.url-input')

const getIdFromUrl = url => {
  const pattern = /status\/(\d+)/
  const match = url.match(pattern)
  let id = null

  if (match) {
    id = match[1]
  }
 
  return id
}

const getMedia = async (url) => {
  console.log('get media for ' + url)
  const id = getIdFromUrl(url)
  const response = await fetch(`/api/tweets/${id}`)
  const data = await response.json()
  
  console.log(data)
  return data
}

$getVideoButton.addEventListener('click', async (e) => {
  const tweetUrl = $urlInput.value
  
  const data = await getMedia(tweetUrl)

  if (data.media) {
    let mediaListHtml = ''

    data.media.videoSources.forEach(item => {
      mediaListHtml += `
        <div style="padding:1rem; font-size:1.5rem;">
          ${item.size} | <a href="${item.url}" download>${item.url}</a></div>
      `
    })

    document.querySelector('.media-preview').innerHTML = `
      <video controls muted src="${data.media.videoSources[0].url}" type="video/mp4">
    `

    document.querySelector('.media-duration').innerHTML = `
      <b>${data.media.duration}</b>
    `

    document.querySelector('.media-list').innerHTML = mediaListHtml

  } else {
    document.querySelector('.media-list').innerHTML = 'error'
  }


})

// https://twitter.com/reesewaters/status/1250815749721141250